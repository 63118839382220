var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text","text":"","dark":_vm.color1IsDark}},[_c('v-toolbar-title',[_vm._v("Registrations")]),_c('v-spacer'),_c('v-text-field',{class:{'mb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"slot":_vm.$vuetify.breakpoint.smAndDown ? 'extension' : null,"color":"color3","append-icon":"fas fa-search","label":"Search","hide-details":"","clearable":"","solo-inverted":"","autofocus":""},slot:_vm.$vuetify.breakpoint.smAndDown ? 'extension' : null,model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(_vm.data.length > 0 || _vm.loading)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 mb-2",attrs:{"dense":"","items":_vm.data,"headers":_vm.headers,"loading":_vm.loading,"options":_vm.page,"footer-props":{'items-per-page-options': [25, 50, 100, -1]}},on:{"update:options":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.event",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.tournament))]),_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.division))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(['Pending', 'Payment Requested'].includes(item.paymentStatus) ? null : item.amount)+" "),(item.quickAdd)?_c('i',{staticClass:"fad fa-user-shield"}):_vm._e()]}},{key:"item.pending",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(['Pending', 'Payment Requested'].includes(item.paymentStatus) ? item.amount : null)+" ")]}},{key:"item.amountRefunded",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm._f("usDollars")(item.amountRefunded)))])]}},{key:"item.externalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("usDollars")(item.externalAmount)))])]}},{key:"item.unix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToLocalDt")(item.unix))+" ")]}},{key:"item.teamName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{deleted: item.isDeleted}},[_vm._v(_vm._s(item.teamName))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","to":{name: 'division-team', params: {tournamentId: item.tournamentId, teamId: item.teamId}}}},[_vm._v(" manage "),_c('v-icon',{staticClass:"ml-3",attrs:{"color":"color3"}},[_vm._v("fas fa-caret-right")])],1)]}}],null,true)})],1):_c('v-card-text',{staticClass:"pa-12 text-center title"},[_vm._v(" Search by entering a team name or player name above ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }