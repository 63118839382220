<template>
  <v-container fluid >
    <v-row dense align="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" text :dark="color1IsDark">
            <v-toolbar-title>Registrations</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              clearable
              v-model="searchTerm"
              solo-inverted
              autofocus
              :class="{'mb-3': $vuetify.breakpoint.smAndDown}"
            ></v-text-field>
          </v-toolbar>
          <v-card-text v-if="data.length > 0 || loading">
            <v-data-table
              dense
              :items="data"
              :headers="headers"
              :loading="loading"
              :options.sync="page"
              :footer-props="{'items-per-page-options': [25, 50, 100, -1]}"
              class="elevation-1 mb-2"
            >
              <template v-slot:[`item.event`]="{ item }">
                <div>{{item.tournament}}</div>
                <div class="grey--text">{{item.division}}</div>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ ['Pending', 'Payment Requested'].includes(item.paymentStatus) ? null : item.amount}} <i class="fad fa-user-shield" v-if="item.quickAdd"></i>
              </template>
              <template v-slot:[`item.pending`]="{ item }">
                {{ ['Pending', 'Payment Requested'].includes(item.paymentStatus) ? item.amount : null}}
              </template>
              <template v-slot:[`item.amountRefunded`]="{ item }">
                <span class="error--text">{{ item.amountRefunded | usDollars }}</span>
              </template>
              <template v-slot:[`item.externalAmount`]="{ item }">
                <span>{{ item.externalAmount | usDollars }}</span>
              </template>
              <template v-slot:[`item.unix`]="{ item }">
                {{ item.unix | unixToLocalDt }}
              </template>
              <template v-slot:[`item.teamName`]="{ item }">
                <span :class="{deleted: item.isDeleted}">{{ item.teamName }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                      text
                      small
                      :to="{name: 'division-team', params: {tournamentId: item.tournamentId, teamId: item.teamId}}"
                    >
                      manage
                      <v-icon color="color3" class="ml-3">fas fa-caret-right</v-icon>
                    </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-else class="pa-12 text-center title">
            Search by entering a team name or player name above
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  props: ['username'],
  data () {
    return {
      loading: false,
      summary: false,
      data: [],
      page: { itemsPerPage: 25 },
      searchTerm: null,
      exporting: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark']),
    showHost () {
      return !!this.data.find(f => f.host !== this.username)
    },
    headers () {
      return [
        {
          text: 'Event',
          align: 'left',
          sortable: true,
          value: 'event',
          show: true
        },
        {
          text: 'Host',
          align: 'left',
          sortable: true,
          value: 'host',
          show: this.showHost
        },
        {
          text: 'Team',
          align: 'left',
          sortable: true,
          value: 'teamName',
          show: true
        },
        { text: 'Players', value: 'playerNames', show: true },
        { text: 'Field', value: 'field', show: this.data.filter(f => f.field !== 'NA').length > 0 },
        {
          text: 'Date Registered',
          align: 'left',
          sortable: true,
          value: 'unix',
          show: true
        },
        {
          text: '$ Paid',
          align: 'left',
          sortable: true,
          value: 'amount',
          show: true
        },
        {
          value: 'actions',
          sortable: false,
          show: true
        }
      ].filter(f => f.show)
    }
  },
  methods: {
    search: debounce(function () {
      this.getRegistrations()
    }, 333),
    getRegistrations () {
      if (!this.searchTerm || typeof this.searchTerm !== 'string' || this.searchTerm.length < 3) return

      this.loading = true
      this.$VBL.organization.registrations.search(this.username, this.searchTerm)
        .then((response) => {
          if (response.data.term === this.searchTerm) {
            this.data = response.data.results
            this.data.forEach((d) => {
              d.dtRegisteredF = moment(d.dtRegistered).format('L LT')
              d.unix = +moment(d.dtRegistered)
              d.players = d.playerNames.split(', ')
            })
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error.response)
          this.loading = false
        })
    }
  },
  watch: {
    searchTerm: 'search'
  }
}
</script>

<style>
  .deleted {
    text-decoration: line-through
  }
</style>
